import { createContext, useCallback, useContext, useReducer } from 'react';
import useSWR, { preload } from 'swr';
import { connect } from 'react-redux';
import { AUTHORITY, CURRENT_ENV } from '../../constants';
import metadata from '../../metadata.json';
import reducer, { InboxReducerState, InboxTab, initialState } from './reducer';

const env = process.env.REACT_APP_API_ENV || CURRENT_ENV;
const basePath = metadata.environments[env].apiUrl.replace(/\/+$/, '');

type InboxContextValue = InboxReducerState & {
  user: FixMe;
  role: CaregiverRole;
  fetcher(path: string): Promise<FixMe>;
  setFilter(filter: string, reasons: string[]): void;
  toggleSortOrder(section: string): void;
  setHistoryCutoffDays(numDays: number): void;
  toggleViewingAllErrands(): void;
  selectCaregiverFilter(caregiver?: SystemUser): void;
  setSectionPage(section: string, page: number): void;
  setLastVisitedTab(tab: InboxTab): void;
  numActiveErrands: number;
};

export const InboxContext = createContext<InboxContextValue>({
  user: undefined,
  role: 'DOCTOR',
  fetcher: () => new Promise((r) => r('')),
  setFilter: () => {},
  toggleSortOrder: () => {},
  setSectionPage: () => {},
  setLastVisitedTab: () => {},
  setHistoryCutoffDays: () => {},
  toggleViewingAllErrands: () => {},
  selectCaregiverFilter: () => {},
  numActiveErrands: 0,
  ...initialState
});

const InboxProvider = ({ authToken, clientId, user, role, authorities, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const hasMedicalManagement = authorities.includes(AUTHORITY.MEDICAL_MANAGEMENT);
  const hasNextGenAccess = authorities.includes(AUTHORITY.HANDLE_NEXT_GEN_PATIENTS);

  const fetcher = async (path: string) => {
    const url = `${basePath}${path}`;
    const res = await fetch(url, {
      headers: {
        authorization: `Bearer ${authToken}`,
        'Accumbo-Client-ID': clientId
      }
    });

    if (!res.ok) {
      const error = new Error('An error occurred while fetching the data.');
      let cause = { url };
      let errorResponse = {};

      try {
        errorResponse = await res.json();
      } catch {
        errorResponse = { body: 'No response data available' };
      }

      cause = { ...cause, ...errorResponse };
      error.cause = cause;
      // @ts-ignore
      error.status = res.status;
      throw error;
    }

    return res.json();
  };

  const { data: caregiverSearchResults } = useSWR<SystemUserSearchResults>(
    hasMedicalManagement
      ? `/admin/users?offset=0&limit=100&q=authorities=in=(caregiver,nurse)&extraFields=authorities`
      : null,
    fetcher
  );

  const { data: activeErrands } = useSWR<ErrandListV2>(hasNextGenAccess ? `/clinic/errand/new` : null, fetcher);

  preload('/clinic/appointment/reasons', fetcher);
  preload('/clinic/errand/reasons', fetcher);
  preload('/clinic/appointment/activities?owner=PATIENT', fetcher);
  preload('/clinic/appointment/activities?owner=CAREGIVER', fetcher);

  const value = {
    fetcher,
    ...state,
    user,
    role,
    numActiveErrands: activeErrands?.page?.totalNumberOfItems || 0,
    caregivers:
      caregiverSearchResults?.members
        ?.filter((m) => m.systemUserExtras.authorities.includes('handleNextGenPatients'))
        .map((m) => m.user) || [],
    setHistoryCutoffDays: (numDays: number) => dispatch({ type: 'setHistoryCutoffDays', numDays }),
    toggleViewingAllErrands: () => dispatch({ type: 'toggleViewingAllErrands' }),
    selectCaregiverFilter: (caregiver: SystemUser) => dispatch({ type: 'selectCaregiverFilter', caregiver }),
    setFilter: (filter: string, reasons: string[]) => dispatch({ type: 'setFilter', filter, reasons }),
    toggleSortOrder: (section: string) => dispatch({ type: 'setSortOrder', section }),
    setSectionPage: useCallback(
      (section: string, page: number) => dispatch({ type: 'setSectionPage', section, page }),
      []
    ),
    setLastVisitedTab: useCallback((tab: InboxTab) => dispatch({ type: 'setLastVisitedTab', tab }), [])
  };

  return <InboxContext.Provider value={value}>{children}</InboxContext.Provider>;
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    clientId: state.auth.clientId,
    user: state.auth.token.user,
    role: state.auth.isNurse ? 'NURSE' : 'DOCTOR',
    authorities: state.auth.authorities
  };
};

export default connect(mapStateToProps)(InboxProvider);

export function useInbox() {
  return useContext(InboxContext);
}

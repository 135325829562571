import { useEffect } from 'react';

export default function useKeydown(key: string, handler?: () => void) {
  useEffect(() => {
    if (!handler) {
      return;
    }

    const keydownListener = (e: KeyboardEvent) => {
      if (e.key === key) {
        handler();
      }
    };

    window.addEventListener('keydown', keydownListener);

    return () => window.removeEventListener('keydown', keydownListener);
  }, [key, handler]);
}

import { forwardRef } from 'react';
import { Dialog as HeadlessDialog } from '@headlessui/react';
import styles from './Dialog.module.scss';
import useKeydown from '../../../hooks/useKeydown';

type Props = {
  title?: string | React.ReactNode;
  type?: 'info' | 'success' | 'danger';
  isOpen: boolean;
  onClose(): void;
  children: React.ReactNode;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  actions?: React.ReactNode;
  hint?: string | React.ReactNode;
  header?: React.ReactNode;
  blur?: boolean;
  overflowAuto?: boolean;
  onEnterPress?: () => void;
  unmount?: boolean;
};

type Ref = HTMLDivElement;

const Dialog = forwardRef<Ref, Props>((props, ref) => {
  const {
    isOpen,
    onClose,
    children,
    actions,
    title,
    size = 'medium',
    type = 'info',
    hint,
    header,
    overflowAuto = true,
    onEnterPress,
    unmount = true
  } = props;

  useKeydown('Enter', onEnterPress);

  return (
    <HeadlessDialog open={isOpen} onClose={onClose} className={styles.wrapper} unmount={unmount}>
      <div className={`${styles.backdrop} ${props.blur ? styles.backdropBlur : ''}`} aria-hidden="true" />
      <div className={styles.panelWrapper}>
        <HeadlessDialog.Panel className={`${styles.panel} ${styles[size]}`}>
          <header className={styles[type]}>
            <div>
              <button onClick={onClose} className={styles.closeButton}></button>
            </div>
            {title ? <HeadlessDialog.Title>{title}</HeadlessDialog.Title> : null}
          </header>
          {header ? <div className={styles.header}>{header}</div> : null}
          <div className={`${styles.childWrapper} ${!overflowAuto ? styles.noOverflowAuto : ''}`} ref={ref}>
            {children}
          </div>
          {actions ? <div className={styles.actions}>{actions}</div> : null}
          {hint ? <div className={styles.hint}>{hint}</div> : null}
        </HeadlessDialog.Panel>
      </div>
    </HeadlessDialog>
  );
});

export default Dialog;
